import type { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  VoiceEmotion,
} from "@heygen/streaming-avatar";
import { usePrevious } from "ahooks";
import IconClose from "components/Icon/IconClose";
import LoaderFadeInOut from "components/Loader/LoaderFadeInOut";
import { CONST } from "lib/const";
import { useIsDesktop } from "lib/device";
import { motion } from "framer-motion";
import {
  useAvatarLogChat,
  useGetAvatarClick,
} from "modules/Tenant/homeTenantApi";
import Image from "next/image";
import React from "react";
import { useEffect, useRef, useState } from "react";

export interface ChatType {
  session_id: string;
  start_chat: number | Date | null;
  end_chat: number | Date | null;
  chat: {
    user: any;
    avatar: any;
  }[];
}

export const InteractiveAvatar = ({
  isCourse = false,
  currentUser,
  isScrolled,
}: {
  isCourse?: boolean;
  currentUser: any;
  isScrolled?: boolean;
}) => {
  const isDesktop = useIsDesktop();
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [debug, setDebug] = useState<string>();
  const [dataAvatar, setDataAvatar] = useState<StartAvatarResponse>();
  const [text, setText] = useState<string>("");
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);
  const [isVoiceChatActive, setIsVoiceChatActive] = useState(false);
  const [micIsLoading, setMicIsLoading] = useState(false);
  const [disabledMic, setDisabledMic] = useState(false);

  const [avatarMessage, setAvatarMessage] = useState<string[]>([]);
  const [avatarEndMessage, setAvatarEndMessage] = useState(false);
  const [userMessage, setUserMessage] = useState<string[]>([]);
  const [userEndMessage, setUserEndMessage] = useState(false);
  const [saveChat, setSaveChat] = useState<ChatType>({
    session_id: "",
    start_chat: null,
    end_chat: null,
    chat: [],
  });

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const [isDisconnected, setIsDisconnected] = useState(false);

  const avatarClickApi = useGetAvatarClick();
  const avatarLogChat = useAvatarLogChat();

  const fetchAccessToken = async () => {
    const trialApiKey = CONST.HEYGEN_APIKEY_TRIAL;
    const apiKey = CONST.HEYGEN_APIKEY;

    const response = await fetch(
      "https://api.heygen.com/v1/streaming.create_token",
      {
        method: "POST",
        headers: {
          "x-api-key": apiKey,
        },
      }
    );

    const { data } = await response.json();
    return data.token;
  };

  const [isHiddenAvatar, setIsHiddenAvatar] = useState(false);
  const [remainingDuration, setRemainingDuration] = useState(0);
  const [showLimitDesc, setShowLimitDesc] = useState(false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (stream) {
      if (remainingDuration > 0) {
        const countdown = setInterval(() => {
          setRemainingDuration((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(countdown);
      } else {
        endSession();
      }
    }
  }, [remainingDuration, stream]);

  useEffect(() => {
    avatarClickApi.doFetch(
      {
        slug: currentUser?.referral,
      },
      {
        onSuccess(data: any) {
          setRemainingDuration(data?.max_duration - data?.duration);

          if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
            setIsHiddenAvatar(true);

            if (stream) {
              endSession();
            }
          }
        },
      }
    );
  }, [currentUser, stream, avatar.current]);

  useEffect(() => {
    if (stream) {
      const interval = setInterval(() => {
        avatarClickApi.doFetch({
          slug: currentUser?.referral,
        });
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [currentUser, stream, avatar.current]);

  const determineAvatarQuality = () => {
    // Get the estimated downlink speed in Mbps
    const downlinkSpeed = (navigator as any).connection?.downlink || 0;

    let quality;

    // Categorize the internet speed
    if (downlinkSpeed < 1) {
      quality = "Low";
    } else if (downlinkSpeed >= 1 && downlinkSpeed < 3) {
      quality = "Medium";
    } else {
      quality = "High";
    }

    // Map the quality to AvatarQuality
    const _AvatarQuality = {
      Low: AvatarQuality.Low,
      Medium: AvatarQuality.Medium,
      High: AvatarQuality.High,
    };

    return _AvatarQuality[quality];
  };

  async function startSession() {
    avatarClickApi.doFetch(
      {
        slug: currentUser?.referral,
      },
      {
        onSuccess(data: any) {
          if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
            endSession();
          }
        },
      }
    );

    setIsLoadingSession(true);
    const newToken = await fetchAccessToken();
    const quality = determineAvatarQuality();

    avatar.current = new StreamingAvatar({
      token: newToken,
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      setDisabledMic(false);
      console.log("Avatar started talking", e);
    });
    avatar.current.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
      setAvatarMessage((prev) => [...prev, message?.detail?.message]);
      console.log("Avatar talking message:", message?.detail?.message);
    });
    avatar.current.on(StreamingEvents.AVATAR_END_MESSAGE, (message) => {
      setAvatarEndMessage(true);
      console.log("Avatar end message:", message);
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      setSaveChat((prev) => ({
        ...prev,
        end_chat: Math.floor(new Date().getTime() / 1000),
      }));
      setAvatarMessage([]);
      setUserMessage([]);
      console.log("Stream disconnected");

      setIsDisconnected(true);
      setIsLoadingSession(false);
      endSession();
    });
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      setSaveChat((prev) => ({
        ...prev,
        start_chat: Math.floor(new Date().getTime() / 1000),
      }));
      console.log("Stream ready:", event.detail);
      setIsLoadingSession(false);
      setStream(event.detail);
      setIsDisconnected(false);
    });
    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      setDisabledMic(true);
      console.log("User started talking:", event);
    });
    avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
      setUserMessage((prev) => [...prev, message?.detail?.message]);
      setUserMessage(message?.detail?.message);
      console.log("User talking message:", message);
    });
    avatar.current.on(StreamingEvents.USER_END_MESSAGE, (message) => {
      setUserEndMessage(true);
      console.log("User end message:", message);
    });
    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      console.log("User stopped talking:", event);
    });
    try {
      const res = await avatar.current.createStartAvatar({
        quality: quality,
        avatarName: "c5c290e4a54444f7b57583682c463376",
        voice: {
          voiceId: currentUser?.tenant?.setting?.heygen_voice,
          rate: 1.0,
          emotion: VoiceEmotion.EXCITED,
        },
        knowledgeId: currentUser?.tenant?.setting?.heygen_knowledge,
        language: currentUser?.tenant?.setting?.heygen_language,
      });

      setDataAvatar(res);
      setSaveChat((prev) => ({
        ...prev,
        session_id: res?.session_id,
        start_chat: Math.floor(new Date().getTime() / 1000),
        chat: [],
      }));
      await avatar.current?.startVoiceChat();
      setIsVoiceChatActive(true);
    } catch (error) {
      setIsLoadingSession(false);

      console.error("Error starting avatar session:", error);
    }
  }

  useEffect(() => {
    if (avatarEndMessage && userEndMessage) {
      setSaveChat((prev) => ({
        ...prev,
        chat: [...prev.chat, { user: userMessage, avatar: avatarMessage }],
      }));

      setAvatarMessage([]);
      setUserMessage([]);

      setAvatarEndMessage(false);
      setUserEndMessage(false);
    }
  }, [avatarEndMessage, userEndMessage]);

  useEffect(() => {
    const data = {
      slug: currentUser?.referral,
      ...saveChat,
    };

    if (isDisconnected) {
      avatarLogChat.doFetch(data, {
        onSuccess(data: any) {
          if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
            setIsHiddenAvatar(true);
          }

          setIsDisconnected(false);
        },
        onError(err) {
          const error: any = err.getActualType();
          console.error("Error saving avatar log chat:", error);

          setIsDisconnected(false);
        },
      });
    }
  }, [isDisconnected]);

  async function handleStartVoiceChat(event) {
    event.stopPropagation();

    setMicIsLoading(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    await avatar.current?.startVoiceChat();
    setMicIsLoading(false);
    setIsVoiceChatActive(true);
  }

  function handleCloseVoiceChat(event) {
    event.stopPropagation();

    setMicIsLoading(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    avatar.current?.closeVoiceChat();
    setMicIsLoading(false);
    setIsVoiceChatActive(false);
  }

  async function handleSpeak() {
    setIsLoadingRepeat(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    await avatar.current.speak({ text: text }).catch((e) => {
      setDebug(e.message);
    });
    setIsLoadingRepeat(false);
  }

  async function handleInterrupt() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    await avatar.current.interrupt().catch((e) => {
      setDebug(e.message);
    });
  }

  async function endSession() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    if (mediaStream.current) {
      mediaStream.current.classList.remove("opacity-100");
      mediaStream.current.classList.add("opacity-0");

      const micButton = document.getElementById("mic-button");
      micButton!.classList.remove("opacity-100");
      micButton!.classList.add("opacity-0");

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    avatar.current.closeVoiceChat();
    await avatar.current.stopAvatar();
    setStream(undefined);
    setIsVoiceChatActive(false);
  }

  const previousText = usePrevious(text);
  useEffect(() => {
    if (!previousText && text) {
      avatar.current?.startListening();
    } else if (previousText && !text) {
      avatar?.current?.stopListening();
    }
  }, [text, previousText]);

  useEffect(() => {
    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug("Playing");
      };
    }
  }, [mediaStream, stream, isScrolled]);

  const stopMediaTracks = (stream: MediaStream) => {
    stream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "audio") {
        track.stop(); // Hentikan track audio
      }
    });
  };

  useEffect(() => {
    return () => {
      // Pastikan stream audio dihentikan ketika komponen unmounted
      if (mediaRecorderRef.current) {
        const stream = mediaRecorderRef.current.stream;
        stopMediaTracks(stream);
      }
    };
  }, []);

  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = (event) => {
    event.stopPropagation();

    setShowSidebar(!showSidebar);
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyText = (event, textToCopy) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true);
      })
      .catch((err) => {
        setCopySuccess(false);
      });
  };

  const scrollToTop = (event) => {
    event.stopPropagation();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  }, [copySuccess]);

  const [volumes, setVolumes] = useState<number[]>(Array(10).fill(0));
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    const handleAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setAudioStream(stream);
        const audioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.fftSize = 256;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const updateVolume = () => {
          analyser.getByteFrequencyData(dataArray);

          if (isVoiceChatActive) {
            const newVolumes = Array.from(
              { length: 10 },
              (_, i) => dataArray[i * 2] / 2
            );
            setVolumes(newVolumes);
          }

          requestAnimationFrame(updateVolume);
        };

        updateVolume();
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    };

    handleAudio();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isVoiceChatActive]);

  useEffect(() => {
    if (!isVoiceChatActive && audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }
  }, [isVoiceChatActive, audioStream]);

  const renderLimit = (customClass: string, title: string, desc: any) => {
    return (
      <div
        className={`${customClass} z-20 p-16 rounded-16`}
        style={{
          backgroundColor: "#D0375CCC",
          backdropFilter: "blur(8px)",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between">
          <p className="font-bold text-b1 text-white_to_white">{title}</p>

          <button
            onClick={() => setShowLimitDesc(false)}
            className="cursor-pointer"
          >
            <IconClose size={20} color="#FFFFFF" />
          </button>
        </div>
        <div className="mt-12">{desc}</div>
      </div>
    );
  };

  return (
    <>
      {!isDesktop &&
        currentUser?.tenant?.setting?.is_heygen_enabled &&
        !isCourse && (
          <>
            {stream && (
              <>
                <div
                  onClick={endSession}
                  className={`fixed w-full h-full inset-0 bg-neutral80_to_dark00 transition-all duration-500 ease-in-out ${
                    stream ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ zIndex: 99999999 }}
                >
                  <div
                    className="relative w-full h-full flex items-end justify-center"
                    style={{ zIndex: 999999999 }}
                  >
                    <video
                      webkit-playsinline
                      playsInline
                      onClick={(e) => e.stopPropagation()}
                      className="w-full h-full object-cover transition-all duration-500 ease-in-out opacity-0"
                      id="sm-video"
                      ref={mediaStream}
                      autoPlay
                      onLoadedMetadata={() => {
                        mediaStream.current!.play();
                        setDebug("Playing");
                        mediaStream.current!.classList.remove("opacity-0");
                        mediaStream.current!.classList.add("opacity-100");

                        const closeButton =
                          document.getElementById("close-button");
                        closeButton!.classList.remove("opacity-0");
                        closeButton!.classList.add("opacity-100");

                        const micButton = document.getElementById("mic-button");
                        micButton!.classList.remove("opacity-0");
                        micButton!.classList.add("opacity-100");
                      }}
                      style={{ objectFit: "cover" }}
                    />

                    <div
                      className="absolute top-0 left-0 px-16 py-10 rounded-br-xl flex justify-center gap-8"
                      style={{
                        background:
                          "linear-gradient(270deg, rgba(85, 31, 193, 0.8) 0%, rgba(243, 122, 65, 0.8) 100%)",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Image
                        src={"/images/star-gaia.svg"}
                        alt="gaia"
                        width={16}
                        height={16}
                      />
                      <p className="text-white_to_white text-b1 font-medium">
                        GAIA
                      </p>
                      <p className="text-white_to_white text-b1 font-light">
                        | goKampus AI Advisor
                      </p>
                    </div>

                    <button
                      id="close-button"
                      className={`absolute transition-all duration-500 ease-in-out opacity-0 hover:cursor-pointer top-10 right-20`}
                      onClick={endSession}
                    >
                      <IconClose size={20} color="#D3D2D7" />
                    </button>

                    <div
                      className="absolute bottom-20 left-20 mb-8 rounded-full flex flex-col gap-4 z-30"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="text-white_to_white text-c2 font-light">
                        Powered by
                      </p>
                      <Image
                        src={"/images/logo-nxtx-dark.png"}
                        alt="nxtx"
                        width={66}
                        height={11}
                      />
                    </div>

                    <div
                      className="z-10 absolute bottom-20 right-20 px-16 py-10 rounded-full cursor-pointer"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                      }}
                      onClick={toggleSidebar}
                    >
                      <Image
                        src={"/images/transcript.svg"}
                        alt="transcript"
                        width={20}
                        height={20}
                      />
                    </div>

                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={`absolute rounded-tl-xl rounded-tr-xl w-full h-[50%] bottom-0 px-16 pb-72 pt-48 transition-all duration-700 ease-in-out ${
                        showSidebar
                          ? "ease-in opacity-100 visible pointer-events-auto"
                          : "ease-out opacity-0 invisible pointer-events-none"
                      }`}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                        backdropFilter: "blur(8px)",
                      }}
                    >
                      <h4
                        className="absolute top-20 left-20 text-white_to_white text-h4 font-medium"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Transkrip Percakapan
                      </h4>

                      <button
                        id="close-button"
                        className="absolute top-20 right-20 hover:cursor-pointer"
                        onClick={toggleSidebar}
                      >
                        <IconClose size={20} color="#D3D2D7" />
                      </button>

                      <div className="overflow-y-auto hide-scrollbar h-full">
                        {saveChat && (
                          <>
                            {saveChat?.chat.map((item, index) => {
                              return (
                                <>
                                  <div className="mb-12">
                                    {item?.user && item?.user != "" && (
                                      <div className="flex justify-end flex-shrink-0 gap-8 mt-12 mb-12">
                                        <div
                                          className="p-20 rounded-xl"
                                          style={{
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.44)",
                                          }}
                                        >
                                          <p className="text-white_to_white font-light text-sm">
                                            {item?.user}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center bg-purple-10 text-purple-60">
                                          {currentUser?.name[0].toUpperCase()}
                                        </div>
                                      </div>
                                    )}

                                    {item?.avatar && item?.avatar != "" && (
                                      <div>
                                        <div className="flex flex-shrink-0 gap-8">
                                          <div
                                            className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center"
                                            style={{
                                              background:
                                                "linear-gradient(270deg, rgba(85, 31, 193, 0.44) 0%, rgba(243, 122, 65, 0.44) 100%)",
                                            }}
                                          >
                                            <Image
                                              src={"/images/star-gaia.svg"}
                                              alt="gaia"
                                              width={24}
                                              height={24}
                                              className="rounded-full"
                                            />
                                          </div>
                                          <p className="text-white_to_white font-light text-sm">
                                            {item?.avatar}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer ml-32 mt-8 flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center">
                                          <Image
                                            onClick={(e) =>
                                              handleCopyText(e, item?.avatar)
                                            }
                                            src={
                                              copySuccess
                                                ? "/images/copied.svg"
                                                : "/images/copy.svg"
                                            }
                                            alt="gaia"
                                            width={24}
                                            height={24}
                                            className="rounded-full"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>

                    {!showSidebar && (
                      <div className="absolute bottom-88 flex gap-8 px-16 py-10 rounded-full bg-transparent">
                        <div className="flex space-x-1 gap-4">
                          {volumes.map((volume, index) => (
                            <motion.div
                              key={index}
                              className="w-4 max-h-8 bg-white_to_white rounded-md"
                              style={{ height: `${Math.max(volume, 10)}px` }}
                              animate={{ scaleY: 1 + volume / 50 }}
                              transition={{
                                type: "spring",
                                stiffness: 300,
                                damping: 15,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    <div
                      className={`absolute ${
                        remainingDuration <= 120 && remainingDuration >= 0
                          ? "bottom-20 left-120"
                          : "bottom-20"
                      } flex gap-8 z-30`}
                    >
                      <button
                        disabled={disabledMic}
                        id="mic-button"
                        onClick={
                          isVoiceChatActive
                            ? handleCloseVoiceChat
                            : handleStartVoiceChat
                        }
                        className={`${
                          disabledMic
                            ? "opacity-50 hover:cursor-not-allowed"
                            : "opacity-100 hover:cursor-pointer"
                        } rounded-full p-10 transition-all duration-500 ease-in-out opacity-0`}
                        style={{
                          backgroundColor: isVoiceChatActive
                            ? "#FFFFFF"
                            : "#D0375C",
                        }}
                      >
                        <Image
                          src={`${
                            isVoiceChatActive
                              ? "/images/Microphone-On.svg"
                              : "/images/Microphone-Off.svg"
                          }`}
                          alt="mic"
                          width={24}
                          height={24}
                        />
                      </button>
                    </div>

                    {showLimitDesc &&
                      renderLimit(
                        "absolute mx-8 bottom-88",
                        "Limit GAIA",
                        <p className="font-light text-b2 text-white_to_white">
                          Durasi Interaktif{" "}
                          <span className="font-bold">GAIA</span> yang dimiliki
                          Perusahaanmu telah habis.
                        </p>
                      )}

                    {remainingDuration <= 120 && remainingDuration >= 0 && (
                      <div className="absolute bottom-20 left-120 z-10 gap-8">
                        <div
                          className="rounded-full pl-50 py-12 pr-10 flex items-center gap-8 text-white_to_white text-b2 font-light"
                          style={{
                            backgroundColor: "#D0375C66",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {formatTime(remainingDuration)}

                          <Image
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowLimitDesc(!showLimitDesc);
                            }}
                            src={"/images/countdown-info.svg"}
                            alt="countdown"
                            width={16}
                            height={16}
                          />
                        </div>
                      </div>
                    )}

                    {micIsLoading && (
                      <div
                        className={`rounded-full absolute ${
                          remainingDuration <= 120 && remainingDuration >= 0
                            ? "bottom-20 left-120"
                            : "bottom-20"
                        } z-40`}
                        style={{
                          width: "44px",
                          height: "44px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-10 -right-18">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {showLimitDesc &&
              renderLimit(
                "fixed bottom-[200px] mx-8",
                "Kuota Tidak Tersedia",
                <p className="font-light text-b2 text-white_to_white">
                  Perusahaanmu tidak memiliki cukup kuota untuk memulai sesi
                  Interaktif <span className="font-bold">GAIA</span>
                </p>
              )}

            {currentUser &&
              !isHiddenAvatar &&
              currentUser?.tenant?.setting?.is_heygen_enabled &&
              CONST.HEYGEN_STATUS == "true" && (
                <div
                  className="fixed bottom-70 right-10"
                  style={{
                    zIndex: 8,
                  }}
                >
                  <div
                    className="my-10 hover:cursor-pointer"
                    onClick={
                      remainingDuration <= 0
                        ? (e) => {
                            e.stopPropagation();

                            setShowLimitDesc(!showLimitDesc);
                          }
                        : startSession
                    }
                  >
                    <Image
                      className="hover:cursor-pointer"
                      src={"/images/nathan.png"}
                      alt="nathan"
                      width={220}
                      height={100}
                    />

                    <div
                      className="rounded-full absolute bottom-18 right-6"
                      style={{ width: "94px", height: "94px" }}
                    >
                      <Image
                        className="hover:cursor-pointer rounded-full"
                        src={"/images/nathan-move.gif"}
                        alt="nathan"
                        width={220}
                        height={100}
                      />
                    </div>

                    {isLoadingSession && (
                      <div
                        className="rounded-full absolute bottom-18 right-6"
                        style={{
                          width: "94px",
                          height: "94px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-32 -right-48">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}

                    {remainingDuration <= 0 && (
                      <div
                        className="rounded-full absolute bottom-16 right-6 flex justify-center items-center"
                        style={{
                          width: "95px",
                          height: "95px",
                          backgroundColor: "#D0375C80",
                        }}
                      >
                        <Image
                          src={"/images/countdown-info.svg"}
                          width={40}
                          height={40}
                          alt="countdown"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
          </>
        )}

      {isDesktop &&
        currentUser?.tenant?.setting?.is_heygen_enabled &&
        !isCourse && (
          <>
            {stream && (
              <>
                <div
                  onClick={endSession}
                  className={`fixed w-full h-full inset-0 bg-neutral80_to_dark00 transition-all duration-500 ease-in-out ${
                    stream ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ zIndex: 99999999 }}
                >
                  <div
                    className="relative w-full h-full flex items-center justify-center px-8 pt-64 pb-6"
                    style={{ zIndex: 999999999 }}
                  >
                    <video
                      webkit-playsinline
                      playsInline
                      onClick={(e) => e.stopPropagation()}
                      className="w-full h-full rounded-md object-cover transition-all duration-500 ease-in-out opacity-0"
                      id="sm-video"
                      ref={mediaStream}
                      autoPlay
                      onLoadedMetadata={() => {
                        mediaStream.current!.play();
                        setDebug("Playing");
                        mediaStream.current!.classList.remove("opacity-0");
                        mediaStream.current!.classList.add("opacity-100");

                        const closeButton =
                          document.getElementById("close-button");
                        closeButton!.classList.remove("opacity-0");
                        closeButton!.classList.add("opacity-100");

                        const micButton = document.getElementById("mic-button");
                        micButton!.classList.remove("opacity-0");
                        micButton!.classList.add("opacity-100");
                      }}
                    />

                    <div
                      className="absolute top-0 left-0 rounded-tl-md mt-64 ml-8 px-16 py-10 rounded-br-xl flex justify-center gap-8"
                      style={{
                        background:
                          "linear-gradient(270deg, rgba(85, 31, 193, 0.8) 0%, rgba(243, 122, 65, 0.8) 100%)",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Image
                        src={"/images/star-gaia.svg"}
                        alt="gaia"
                        width={16}
                        height={16}
                      />
                      <p className="text-white_to_white text-b1 font-medium">
                        GAIA
                      </p>
                      <p className="text-white_to_white text-b1 font-light">
                        | goKampus AI Advisor
                      </p>
                    </div>

                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="absolute w-[98vw] bottom-6 h-64 rounded-br-md rounded-bl-md"
                      style={{ backdropFilter: "blur(64px)" }}
                    ></div>

                    <div
                      className="absolute bottom-24 left-40 pt-10 rounded-full flex flex-col gap-4"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="text-white_to_white text-c1 font-light">
                        Powered by
                      </p>
                      <Image
                        src={"/images/logo-nxtx-dark.png"}
                        alt="nxtx"
                        width={66}
                        height={11}
                      />
                    </div>

                    <button
                      id="close-button"
                      className="absolute top-96 right-40 transition-all duration-500 ease-in-out opacity-0 hover:cursor-pointer"
                      onClick={endSession}
                    >
                      <IconClose size={20} color="#D3D2D7" />
                    </button>

                    <div
                      className="absolute bottom-14 right-40 px-16 py-10 rounded-full flex justify-center gap-8 cursor-pointer"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                      }}
                      onClick={toggleSidebar}
                    >
                      <Image
                        src={"/images/transcript.svg"}
                        alt="transcript"
                        width={20}
                        height={20}
                      />
                      <p className="text-white_to_white text-b1 font-light">
                        Transkrip Percakapan
                      </p>
                    </div>

                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={`absolute rounded-xl w-[35%] h-[65%] bottom-83 right-40 px-16 pb-20 pt-48 transition-all duration-700 ease-in-out ${
                        showSidebar
                          ? "ease-in opacity-100 visible pointer-events-auto"
                          : "ease-out opacity-0 invisible pointer-events-none"
                      }`}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                        backdropFilter: "blur(8px)",
                      }}
                    >
                      <h4
                        className="absolute top-20 left-20 text-white_to_white text-h4 font-medium"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Transkrip Percakapan
                      </h4>

                      <button
                        id="close-button"
                        className="absolute top-20 right-20 hover:cursor-pointer"
                        onClick={toggleSidebar}
                      >
                        <IconClose size={20} color="#D3D2D7" />
                      </button>

                      <div className="overflow-y-auto hide-scrollbar h-full">
                        {saveChat && (
                          <>
                            {saveChat?.chat.map((item, index) => {
                              return (
                                <>
                                  <div className="mb-12 px-28">
                                    {item?.user && item?.user != "" && (
                                      <div className="flex justify-end flex-shrink-0 gap-8 mt-12 mb-12">
                                        <div
                                          className="p-20 rounded-xl"
                                          style={{
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.44)",
                                          }}
                                        >
                                          <p className="text-white_to_white font-light text-sm">
                                            {item?.user}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center bg-purple-10 text-purple-60">
                                          {currentUser?.name[0].toUpperCase()}
                                        </div>
                                      </div>
                                    )}

                                    {item?.avatar && item?.avatar != "" && (
                                      <div>
                                        <div className="flex flex-shrink-0 gap-8">
                                          <div
                                            className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center"
                                            style={{
                                              background:
                                                "linear-gradient(270deg, rgba(85, 31, 193, 0.44) 0%, rgba(243, 122, 65, 0.44) 100%)",
                                            }}
                                          >
                                            <Image
                                              src={"/images/star-gaia.svg"}
                                              alt="gaia"
                                              width={24}
                                              height={24}
                                              className="rounded-full"
                                            />
                                          </div>
                                          <p className="text-white_to_white font-light text-sm">
                                            {item?.avatar}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer ml-32 mt-8 flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center">
                                          <Image
                                            onClick={(e) =>
                                              handleCopyText(e, item?.avatar)
                                            }
                                            src={
                                              copySuccess
                                                ? "/images/copied.svg"
                                                : "/images/copy.svg"
                                            }
                                            alt="gaia"
                                            width={24}
                                            height={24}
                                            className="rounded-full"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="absolute bottom-88 flex gap-8 px-16 py-10 rounded-full bg-transparent">
                      <div className="flex space-x-1 gap-4">
                        {volumes.map((volume, index) => (
                          <motion.div
                            key={index}
                            className="w-4 max-h-8 bg-white_to_white rounded-md"
                            style={{ height: `${Math.max(volume, 10)}px` }}
                            animate={{ scaleY: 1 + volume / 50 }}
                            transition={{
                              type: "spring",
                              stiffness: 300,
                              damping: 15,
                            }}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="absolute bottom-14 flex gap-8 z-20">
                      <button
                        disabled={disabledMic}
                        id="mic-button"
                        onClick={
                          isVoiceChatActive
                            ? handleCloseVoiceChat
                            : handleStartVoiceChat
                        }
                        className={`${
                          disabledMic
                            ? "opacity-50 hover:cursor-not-allowed"
                            : "opacity-100 hover:cursor-pointer"
                        } rounded-full p-10 transition-all duration-500 ease-in-out opacity-0`}
                        style={{
                          backgroundColor: isVoiceChatActive
                            ? "#FFFFFF"
                            : "#D0375C",
                        }}
                      >
                        <Image
                          src={`${
                            isVoiceChatActive
                              ? "/images/Microphone-On.svg"
                              : "/images/Microphone-Off.svg"
                          }`}
                          alt="mic"
                          width={24}
                          height={24}
                        />
                      </button>
                    </div>

                    {showLimitDesc &&
                      renderLimit(
                        "absolute bottom-88",
                        "Limit GAIA",
                        <p className="font-light text-b2 text-white_to_white">
                          Durasi Interaktif{" "}
                          <span className="font-bold">GAIA</span> yang dimiliki
                          Perusahaanmu telah habis.
                        </p>
                      )}

                    {remainingDuration <= 120 && remainingDuration >= 0 && (
                      <div className="absolute bottom-14 z-10 gap-8">
                        <div
                          className="rounded-full pl-60 py-12 pr-20 flex items-center gap-8 text-white_to_white text-b2 font-light"
                          style={{
                            backgroundColor: "#D0375C66",
                            transform: "translateX(46px)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {formatTime(remainingDuration)}

                          <Image
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowLimitDesc(!showLimitDesc);
                            }}
                            src={"/images/countdown-info.svg"}
                            alt="countdown"
                            width={16}
                            height={16}
                          />
                        </div>
                      </div>
                    )}

                    {micIsLoading && (
                      <div
                        className="rounded-full absolute bottom-14 z-30"
                        style={{
                          width: "44px",
                          height: "44px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-10 -right-18">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {showLimitDesc &&
              renderLimit(
                "fixed bottom-[150px] right-40 max-w-[350px]",
                "Kuota Tidak Tersedia",
                <p className="font-light text-b2 text-white_to_white">
                  Perusahaanmu tidak memiliki cukup kuota untuk memulai sesi
                  Interaktif <span className="font-bold">GAIA</span>
                </p>
              )}

            {currentUser &&
              !isHiddenAvatar &&
              currentUser?.tenant?.setting?.is_heygen_enabled &&
              CONST.HEYGEN_STATUS == "true" && (
                <div
                  className="fixed bottom-10 right-40"
                  style={{
                    zIndex: 999,
                  }}
                >
                  <div
                    className="my-10 hover:cursor-pointer"
                    onClick={
                      remainingDuration <= 0
                        ? (e) => {
                            e.stopPropagation();

                            setShowLimitDesc(!showLimitDesc);
                          }
                        : startSession
                    }
                  >
                    <Image
                      className="hover:cursor-pointer"
                      src={"/images/nathan.png"}
                      alt="nathan"
                      width={220}
                      height={100}
                    />

                    <div
                      className="rounded-full absolute bottom-18 right-6"
                      style={{ width: "94px", height: "94px" }}
                    >
                      <Image
                        className="hover:cursor-pointer rounded-full"
                        src={"/images/nathan-move.gif"}
                        alt="nathan"
                        width={220}
                        height={100}
                      />
                    </div>

                    {isLoadingSession && (
                      <div
                        className="rounded-full absolute bottom-18 right-6"
                        style={{
                          width: "94px",
                          height: "94px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-32 -right-48">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}

                    {remainingDuration <= 0 && (
                      <div
                        className="rounded-full absolute bottom-16 right-6 flex justify-center items-center"
                        style={{
                          width: "95px",
                          height: "95px",
                          backgroundColor: "#D0375C80",
                        }}
                      >
                        <Image
                          src={"/images/countdown-info.svg"}
                          width={40}
                          height={40}
                          alt="countdown"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
          </>
        )}
    </>
  );
};
